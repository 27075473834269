import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'next-i18next';

// Icons
import EyeIcon from '../../../public/icons/view_password.svg';

// Components
import FlashMessage from '../../molecules/FlashMessage/FlashMessage';

import styles from './InputField.module.scss';

function InputField({
  id, name, label, value, full, noLeftBorder, className, error, isRequired, ...props
}) {
  const { t } = useTranslation(['common']);

  const [viewPassword, setViewPassword] = useState(false);

  return (
    <div className={`w-100 ${className || ''}`}>
      <div
        className={`
          ${styles.input_wrapper}
          ${error ? styles.error : ''}
          flex a-center
          ${full ? 'w-100' : ''}
          ${noLeftBorder ? styles.no_left_border : ''}
        `}
      >
        <input
          id={id}
          name={name}
          placeholder={label}
          value={value}
          className={value ? styles.has_value : ''}
          {...props}
          type={viewPassword ? 'text' : props.type}
        />
        {label ? (
          <label htmlFor={id}>
            {label}
            {isRequired ? <span>*</span> : ''}
          </label>
        ) : null}

        {props.type === 'password' ? (
          <button
            type="button"
            className={`${styles.view_pass} flex center p-10 ${viewPassword ? styles.active : ''}`}
            onClick={() => setViewPassword(!viewPassword)}
          >
            <EyeIcon />
          </button>
        ) : null}
      </div>
      {error ? (
        <div className="m-t-10 flex">
          <FlashMessage message={t(error)} color="error" />
        </div>
      ) : null}
    </div>
  );
}

InputField.propTypes = {
  label: PropTypes.string,
  full: PropTypes.bool,
  noLeftBorder: PropTypes.bool,
  isRequired: PropTypes.bool,
};

InputField.defaultProps = {
  label: null,
  full: false,
  noLeftBorder: false,
  isRequired: false,
};

export default InputField;
