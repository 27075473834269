import React from 'react';
import PropTypes from 'prop-types';
import Link from 'next/link';

// Utils
import { useMediaQuery } from 'react-responsive';
import { useTranslation } from 'next-i18next';

// Images
import LogoLogin from '../../../public/images/logo_login.svg';

// Components
import Button from '../../atoms/Button/Button';

import styles from './LoginLayout.module.scss';

function LoginLayout({ children, isRegister }) {
  const { t } = useTranslation(['common']);
  const isMobile = useMediaQuery({ maxWidth: 768 });

  return (
    <section className={`${styles.login_wrapper} flex a-stretch mobile-p-b-40`}>
      <div className="flex center mobile-a-start">
        <div className={`${styles.login_block} flex d-col a-stretch`}>
          <h1 className={`${styles.mobile_order_1} fs-34 fw-700 m-b-10 mobile-fs-24 mobile-m-b-0`}>
            {isRegister ? t('register.title') : t('login.title')}
          </h1>

          <p
            className={`
              ${styles.mobile_order_3} text-c-black-600 fw-400 fs-14 
              ${isMobile ? 'text-center' : ''}
            `}
          >
            {isRegister ? t('register.alreadyRegistred') : t('login.noAccountYet')}

            {isMobile ? (
              <Link href={isRegister ? '/login' : '/register'} passHref>
                <Button
                  text="uppercase"
                  classNames="m-t-20"
                  full
                  color="inverted-secondary"
                >
                  {isRegister ? t('register.signInMobile') : t('login.subscribeMobile')}
                </Button>
              </Link>
            ) : (
              <>
                {' '}
                <Link href={isRegister ? '/login' : '/register'} passHref>
                  <a className="text-c-secondary fw-500">
                    {isRegister ? t('register.signIn') : t('login.subscribe')}
                  </a>
                </Link>
              </>
            )}
          </p>

          <div className={styles.mobile_order_2}>
            {children}
          </div>
        </div>
      </div>
      <div className={`${styles.logo_block} flex a-end j-end`}>
        <LogoLogin />
      </div>
    </section>
  );
}

LoginLayout.propTypes = {
  children: PropTypes.node.isRequired,
  isRegister: PropTypes.bool,
};

LoginLayout.defaultProps = {
  isRegister: false,
};

export default LoginLayout;
