import { pick } from 'lodash';

const initUser = (userToken, stateDispatch, getConnectedUser) => {
  if (userToken?.token) {
    getConnectedUser(userToken.token).then((res) => {
      if (res?.data) {
        stateDispatch({
          type: 'SET_USER',
          payload: pick(res.data, [
            'firstname', 'lastname', 'email', 'birthdate', 'localeId', 'nationalityId', 'properPlayerId', 'phone',
          ]),
        });
      } else {
        console.error(res.response.data);
      }
    });
  }
};

export default initUser;
