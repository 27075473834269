import useApi from './useApi';

const useAuthService = () => {
  const {
    requestState, payload, error, api,
  } = useApi();

  const postSignIn = (user) => api({
    method: 'post',
    url: '/auth/token',
    data: {
      grant_type: 'password',
      ...user,
    },
  });

  const getConnectedUser = (token) => api({
    method: 'get',
    url: 'clients/me',
    headers: {
      'X-Authorization': `Bearer ${token}`,
    },
  });

  const postResetPasswordInit = (email, siteUrl, localeId) => api({
    method: 'post',
    url: `/auth/init-password-reset?localeId=${localeId || 2}`,
    data: {
      email,
      redirectUrl: `${siteUrl}/password/reset?token=TOKEN`,
    },
  });

  const postResetPasswordClose = (token, password) => api({
    method: 'post',
    url: '/auth/close-password-reset',
    data: {
      token,
      password,
    },
  });

  const postSignUp = (newUser, localeId) => api({
    method: 'post',
    url: `/clients/me?localeId=${localeId || 2}`,
    data: newUser,
  });

  return [
    requestState,
    payload,
    error,
    {
      postSignIn,
      getConnectedUser,
      postResetPasswordInit,
      postResetPasswordClose,
      postSignUp,
    }];
};

export default useAuthService;
