import React, { useEffect, useState } from 'react';
import Head from 'next/head';

// Utils
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';
import { useTranslation } from 'next-i18next';
import { useFormik } from 'formik';
import { useRouter } from 'next/router';
import Link from 'next/link';
import loginFormSchema from '../src/utils/validationSchema/loginForm';
import useStorage from '../src/hooks/useStorage';
import initUser from '../src/utils/auth/initUser';
import { useAppContext } from '../src/store/AppContext';

// Components
import LoginLayout from '../components/layout/LoginLayout/LoginLayout';
import Button from '../components/atoms/Button/Button';
import InputField from '../components/atoms/InputField/InputField';
import FlashMessage from '../components/molecules/FlashMessage/FlashMessage';

// Services
import useAuthService from '../src/services/auth';

function Login() {
  const { t } = useTranslation(['common']);
  const router = useRouter();
  const { setItem, getItem, removeItem } = useStorage();
  const [{ user }, stateDispatch] = useAppContext();

  const [loginError, setLoginError] = useState(null);

  const [postSignInState, , , { postSignIn }] = useAuthService();
  const [getConnectedUserState, , , { getConnectedUser }] = useAuthService();

  useEffect(() => {
    if (user) {
      const redirect = getItem('redirect') || null;
      const previousPath = getItem('previousPath')
        && getItem('previousPath').includes('book')
        ? getItem('previousPath')
        : null;
      removeItem('redirect');
      router.push(redirect || previousPath || '/account/camps');
    }
    if (getItem('token') && !user) initUser(getItem('token'), stateDispatch, getConnectedUser);
  }, [user]);

  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
    },
    onSubmit: (values) => {
      setLoginError(null);
      postSignIn(values).then((res) => {
        if (res?.data?.access_token && res?.data?.refresh_token) {
          setItem('token', {
            token: res.data.access_token,
            refresh_token: res.data.refresh_token,
          });
          if (getItem('token')) initUser(getItem('token'), stateDispatch, getConnectedUser);
        } else if (res.response?.data?.code) {
          setLoginError(t(`login.errors.${res.response.data.code}`));
        }
      });
    },
    validationSchema: loginFormSchema,
    validateOnBlur: true,
  });

  return (
    <>
      <Head>
        <title>
          {t('login.title')}
        </title>
      </Head>
      <LoginLayout>
        {getItem('redirect') === '/book/players' ? (
          <div className="m-t-20">
            <FlashMessage message={t('login.finalizeBooking')} />
          </div>
        ) : null}
        <form
          onSubmit={formik.handleSubmit}
          className="flex d-col m-t-40 m-b-50 mobile-m-t-20"
        >
          <p className="text-c-primary fw-500 m-b-10">{t('login.accessAccount')}</p>

          {router.query?.reset ? (
            <div className="m-t-10 m-b-10">
              <FlashMessage color="success" message={t('newPassword.success')} />
            </div>
          ) : null}

          <InputField
            id="loginEmail"
            name="email"
            type="email"
            label={t('login.email')}
            error={formik.errors.email && formik.touched.email ? formik.errors.email : null}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.email}
            full
            className="m-t-10 m-b-10"
            isRequired
          />
          <InputField
            id="loginPassword"
            name="password"
            type="password"
            label={t('login.password')}
            error={formik.errors.password && formik.touched.password ? formik.errors.password : null}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.password}
            full
            className="m-t-10 m-b-10"
            isRequired
          />

          <div className="w-100 flex j-end m-b-20">
            <Link href="/password/lost" passHref>
              <a className="text-c-secondary fs-14 fw-500 p-t-10 p-b-10">{t('login.lostPassword')}</a>
            </Link>
          </div>

          {loginError ? (
            <div>
              <FlashMessage message={loginError} color="error" />
            </div>
          ) : null}

          <Button
            text="uppercase"
            full
            type="submit"
            classNames="m-t-20"
            disabled={
              !formik.dirty
              || !formik.isValid
              || postSignInState === 'loading'
              || getConnectedUserState === 'loading'
            }
            isLoading={
              postSignInState === 'loading'
              || getConnectedUserState === 'loading'
            }
          >
            {t('login.submit')}
          </Button>
        </form>
      </LoginLayout>
    </>
  );
}

export async function getServerSideProps({ locale }) {
  return {
    props: {
      ...(await serverSideTranslations(
        locale || 'fr',
        ['common'],
      )),
    },
  };
}

export default Login;
